import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  SkeletonText,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Calendar from "../Calendar/calendar";
import Time from "../Time/Time";
import PropertyDetails from "../PropertyDetails/PropertyDetails";
import CustomButton from "../Button/CustomButton";
import useProductStore from "../../zustand/useProductStore";

const Details = ({ handleNextStep, handlePreviousStep }) => {
  // const [selectedOption, setSelectedOption] = useState("As soon as possible")

  const formatShortWeekday = (locale, date) => {
    const weekday = date.toLocaleDateString(locale, { weekday: "short" });
    return weekday.slice(0, 1);
  };

  const {
    propertyDetails,
    setPropertyDetails,
    updateProperty,
    addProperty,
    properties,
  } = useProductStore();

  const [selectedDate, setSelectedDate] = useState(null);
  const toast = useToast();

  const validateFields = () => {
    const errors = {};
    const {
      address,
      propertyType,
      propertySize,
      access,
      // dateTime,
      date,
      time,
      officeName,
      officeAddress,
      tenantName,
      tenantNumber,
      porterEmail,
      boxCode,
    } = propertyDetails;

    if (!address) {
      errors.address = "Address is required.";
    }
    if (!propertyType) {
      errors.propertyType = "Property type is required.";
    }
    if (propertyType && !propertySize) {
      errors.propertySize = "Property size is required.";
    }
    if (!access) {
      errors.access = "Access to property is required.";
    }
    if (
      date.length === 0 &&
      propertyDetails.selectedOption === "Choose preferred Date and Time"
    ) {
      errors.date = "Date is required.";
    }
    if (
      time.length === 0 &&
      propertyDetails.selectedOption === "Choose preferred Date and Time"
    ) {
      errors.time = "Time is required.";
    }

    // if (
    //   dateTime.length === 0 &&
    //   propertyDetails.selectedOption === "Choose preferred Date and Time"
    // ) {
    //   errors.dateTime = "Date and Time is required.";
    // }

    // if (
    //   dateTime.length > 0 &&
    //   propertyDetails.selectedOption === "Choose preferred Date and Time"
    // ) {
    //   dateTime.forEach((date) => {
    //     if (date.time.length === 0) {
    //       errors.dateTime = "Time is required For all selected dates.";
    //     }
    //   });
    // }

    if (access) {
      switch (access.value) {
        case "Keys In Office":
          if (!officeName) errors.officeName = "Office Name is required.";
          if (!officeAddress)
            errors.officeAddress = "Office Address is required.";
          break;
        case "Vendor/Tenant":
          if (!tenantName) errors.tenantName = "Contact Name is required.";
          if (!tenantNumber)
            errors.tenantNumber = "Contact Number is required.";
          break;
        case "Concierge/Porter":
          if (!porterEmail)
            errors.porterEmail =
              "Email Address of Concierge/Porter is required.";
          break;
        case "Keybox":
          if (!boxCode) errors.boxCode = "Keybox Code is required.";
          break;
        default:
          break;
      }
    }

    return errors;
  };

  const existingPropertyIndex = properties.findIndex(
    (property) => property.id === propertyDetails.id
  );

  const handleSubmit = () => {
    const errors = validateFields();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      Object.values(errors).forEach((error) => {
        toast({
          title: error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
    } else {
      if (existingPropertyIndex > -1) {
        updateProperty(existingPropertyIndex, propertyDetails);
      } else {
        addProperty(propertyDetails, true);
      }
      handleNextStep();
    }
  };

  const AddProperty = () => {
    const errors = validateFields();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      Object.values(errors).forEach((error) => {
        toast({
          title: error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
    } else {
      addProperty(propertyDetails);
      setPropertyDetails({
        address: "",
        propertyType: null,
        propertySize: null,
        access: null,
        comments: "",
        // dateTime: [],
        date: [],
        time: [],
        officeName: "",
        officeAddress: "",
        tenantName: "",
        tenantNumber: "",
        porterEmail: "",
        boxCode: "",
        selectedOption: "As soon as possible",
        selectedServices: [],
        selectedServicesTypes: {},
        selectedBundle: [],
        selectedAddOn: [],
        servicePrice: {},
        discountedPrice: 0,
        price: 0,
        productService: [],
        productBundle: [],
        productAddOn: [],
        id: Date.now(),
        externalImages: [],
      });

      handlePreviousStep();
    }
  };

  return (
    <Container maxW="7xl" py={2} mt={6} mb={8}>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={6}
      >
        <Box>
          <Heading as={"h3"} variant={"h3"} mb={4}>
            {" "}
            Pick date and time{" "}
          </Heading>
          <Flex
            flexDirection={"column"}
            maxW={"xl"}
            w={"100%"}
            mx={{ base: "auto", md: "0" }}
          >
            <Flex
              flexDirection={"column"}
              width={"max-content"}
              // maxWidth={"330px"}
              gap={2}
              mb={4}
            >
              <Button
                width={"100%"}
                onClick={() =>
                  setPropertyDetails({ selectedOption: "As soon as possible" })
                }
                bg={
                  propertyDetails.selectedOption === "As soon as possible"
                    ? "green.400"
                    : "transparent"
                }
                borderColor={"gray.300"}
                borderWidth={1}
                fontSize={"13px"}
                justifyContent={"start"}
              >
                {" "}
                As soon as possible{" "}
              </Button>

              <Button
                width={"100%"}
                onClick={() =>
                  setPropertyDetails({
                    selectedOption: "Choose preferred Date and Time",
                  })
                }
                bg={
                  propertyDetails.selectedOption ===
                  "Choose preferred Date and Time"
                    ? "green.400"
                    : "transparent"
                }
                borderColor={"gray.300"}
                borderWidth={1}
                fontSize={"13px"}
                justifyContent={"start"}
              >
                {" "}
                Choose preferred Date and Time{" "}
              </Button>
            </Flex>
            {propertyDetails.selectedOption ===
              "Choose preferred Date and Time" && (
              <>
                <Flex
                  gap={"6"}
                  alignItems={"flex-start"}
                  flexDirection={{ base: "column", sm: "row" }}
                >
                  <Calendar
                    classes={{
                      dayTile: "calendar-day",
                      activeDay: "calendar-day-active",
                      selectedDay: "calendar-day-selected",
                    }}
                    formatShortWeekday={formatShortWeekday}
                    minDate={new Date()}
                    calendarType="hebrew"
                    tileDisabled={({ date, view }) =>
                      view === "month" &&
                      (date.getDay() === 0 || date.getDay() === 6)
                    }
                    showNeighboringMonth={false}
                    onChange={(selectedOption) =>
                      setSelectedDate(selectedOption)
                    }
                    prev2Label={null}
                    next2Label={null}
                    value={selectedDate}
                  />
                  <Time date={selectedDate} />
                </Flex>
                <Flex
                  flexDirection={"column"}
                  alignItems={"center"}
                  color={"#00000066"}
                  textAlign={"center"}
                  mt={4}
                >
                  <Text fontWeight={600}> You can select multiple slots </Text>
                  <Text fontWeight={600}>
                    {" "}
                    We will come back with the confirmation or the nearest
                    availability in email{" "}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Box>

        <Box>
          <Heading as={"h3"} variant={"h3"} mb={4} textAlign={"center"}>
            {" "}
            Property details{" "}
          </Heading>
          <PropertyDetails />
          <Flex
            flexDirection={"column"}
            maxWidth={"200px"}
            width={"100%"}
            mx={"auto"}
            my={8}
            gap={4}
          >
            <CustomButton
              text={"Continue"}
              bgColor={"green.500"}
              onClick={handleSubmit}
            />
            <CustomButton text={"Back"} onClick={handlePreviousStep} />
            <CustomButton text={"Add Another Property"} onClick={AddProperty} />
          </Flex>
        </Box>
      </Grid>
    </Container>
  );
};

export default Details;
