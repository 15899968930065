// import useProductStore from "../../zustand/useProductStore";
// import dayjs from "dayjs";
// import { useEffect, useState } from "react";
// import ReactCalendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

// // helpers
// const dateAlreadyClicked = (dates, date) =>
//   dates.some((d) => dayjs(date).isSame(dayjs(d), "day"));
// const datesExcept = (dates, date) =>
//   dates.filter((d) => !dayjs(date).isSame(dayjs(d), "day"));

// const Calendar = ({ classes, ...props }) => {
//   const { value, onChange, ...rest } = props;
//   const { propertyDetails, setPropertyDetails } = useProductStore();
//   const [dates, setDates] = useState(
//     propertyDetails?.dateTime?.map((d) => d.date) || []
//   );

//   useEffect(() => {
//     const modifiedDates = dates.map((date) =>
//       dayjs(date).toDate().toDateString()
//     );
//     const updatedDates = modifiedDates.map((date) => ({
//       date: date,
//       time:
//         propertyDetails?.dateTime?.find((option) => option.date === date)
//           ?.time || [],
//     }));
//     setPropertyDetails({ dateTime: updatedDates });
//   }, [dates]);

//   const onClickDay = (date) => {
//     // if day is already clicked, remove it from state
//     if (dateAlreadyClicked(dates, date)) {
//       if (dayjs(date).toDate().toDateString() === value) {
//         setDates(datesExcept(dates, date));
//         if (props.onChange)
//           onChange(
//             dayjs(
//               dates
//                 .filter(
//                   (e) =>
//                     dayjs(e).toDate().toDateString() !==
//                     dayjs(date).toDate().toDateString()
//                 )
//                 .at(-1)
//             )
//               .toDate()
//               .toDateString()
//           );
//       } else {
//         if (props.onChange) onChange(dayjs(date).toDate().toDateString());
//       }
//     } else {
//       setDates([...dates, date]);
//       if (props.onChange) onChange(dayjs(date).toDate().toDateString());
//     }
//   };

//   const tileClassName = ({ date, view }) => {
//     const classNames = [classes.dayTile];
//     classNames.push(classes.dayTile);
//     // give active days a special class
//     if (dateAlreadyClicked(dates, date)) classNames.push(classes.activeDay);

//     if (view === "month") {
//       const day = date.getDay();
//       if (day === 0 || day === 6) {
//         classNames.push("calendar-day-weekend");
//       }
//     }

//     if (dayjs(date).toDate().toDateString() === value)
//       classNames.push(classes.selectedDay);

//     return classNames;
//   };

//   return (
//     <ReactCalendar
//       tileClassName={tileClassName}
//       onClickDay={onClickDay}
//       {...rest}
//       onChange={() => {}}
//       value={null}
//     />
//   );
// };

// export default Calendar;

import useProductStore from "../../zustand/useProductStore";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// helpers
const dateAlreadyClicked = (dates, date) =>
  dates.some((d) => dayjs(date).isSame(dayjs(d), "day"));
const datesExcept = (dates, date) =>
  dates.filter((d) => !dayjs(date).isSame(dayjs(d), "day"));

const Calendar = ({ classes, ...props }) => {
  const { propertyDetails, setPropertyDetails } = useProductStore();
  const [dates, setDates] = useState(propertyDetails?.date || []);

  useEffect(() => {
    setPropertyDetails({ date: dates });
  }, [dates]);

  const onClickDay = (date) => {
    if (dateAlreadyClicked(dates, date)) {
      setDates(datesExcept(dates, date));
    } else {
      setDates([...dates, date]);
    }
  };

  const tileClassName = ({ date, view }) => {
    const classNames = [classes.dayTile];
    if (dateAlreadyClicked(dates, date)) classNames.push(classes.activeDay);

    if (view === "month") {
      const day = date.getDay();
      if (day === 0 || day === 6) {
        classNames.push("calendar-day-weekend");
      }
    }

    return classNames.join(" ");
  };

  return (
    <ReactCalendar
      tileClassName={tileClassName}
      onClickDay={onClickDay}
      {...props}
      value={null}
    />
  );
};

export default Calendar;
