import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import OrderDetails from "./OrderDetails";
import BillingDetails from "./BillingDetails";
import CustomButton from "../Button/CustomButton";
import ProductDetails from "./ProductDetails";
import Thankyou from "../Thankyou/Thankyou";
import useProductStore from "../../zustand/useProductStore";
import useOrderStore from "../../zustand/useOrderStore";
import PayPalComponent from "../PaPalComponent/PayPalComponent";
import useRevolutPayment from "../../hooks/useRevolutPayment"; // Adjust the path to your hook
import RevolutPaymentOverlay from "./PaymentOverlay";
import revolutLogo from "../../assets/images/revolut.png";

const Checkout = ({ setActiveStep, handleReset, handlePreviousStep }) => {
  const [showThankyou, setShowThankyou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { properties } = useProductStore();
  const { orderDetails } = useOrderStore();
  const [paymentOption, setPaymentOption] = useState("Apple Pay");
  const [orderId, setOrderId] = useState(null);
  const [total, setTotal] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOverlayVisible,
    startPayment,
    focusPopup,
    closePayment,
    paymentStatus,
  } = useRevolutPayment();
  const toast = useToast();

  document.getElementsByTagName("body")[0].style.overflow =
    isOverlayVisible || paymentStatus === null ? "hidden" : "auto";

  const validateFields = () => {
    const errors = {};
    const { name, contact, email, acceptTC } = orderDetails;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name) errors.name = "Name is required.";
    if (!contact) errors.contact = "Contact Number is required.";
    // if (!email) errors.email = "Email is required.";
    if (!email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid Email Format.";
    }
    if (!acceptTC) errors.acceptTC = "Please accept Terms and Conditions.";

    return errors;
  };

  const deviceSupportsPopups = (ua) => {
    if (typeof ua === "undefined") {
      ua = navigator.userAgent;
    }

    // Helper functions
    const isIos = (ua) => /iPhone|iPod|iPad/.test(ua);
    const isAndroid = (ua) => /Android/.test(ua);
    const isOperaMini = (ua) => /Opera Mini/.test(ua);
    const isStandAlone = () =>
      window.matchMedia("(display-mode: standalone)").matches;

    // Checks for various devices and browsers
    return !(
      /(iPhone|iPod|iPad|Macintosh).*AppleWebKit(?!.*Safari)|.*WKWebView/i.test(
        ua
      ) ||
      /\bwv\b/.test(ua) ||
      /Android.*Version\/(\d)\.(\d)/i.test(ua) ||
      (isIos(ua) &&
        (/\bGSA\b/.test(ua) ||
          /.+AppleWebKit(?!.*Safari)|.*WKWebView/.test(ua))) ||
      (isAndroid(ua) && /Version\/[\d.]+/.test(ua) && !isOperaMini(ua)) ||
      isOperaMini(ua) ||
      /FxiOS/i.test(ua) ||
      /EdgiOS/i.test(ua) ||
      /FBAN/.test(ua) ||
      /FBAV/.test(ua) ||
      /QQBrowser/.test(ua) ||
      (typeof process !== "undefined" &&
        process.versions &&
        process.versions.electron) ||
      /Macintosh.*AppleWebKit(?!.*Safari)/i.test(ua) ||
      isStandAlone()
    );
  };

  const handlePlaceOrder = () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => {
        toast({
          title: error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
      return;
    }

    setIsLoading(true);
    const orderData = {
      ...orderDetails,
      orderData: properties,
      paymentMethod: paymentOption,
    };
    let windowReference = null;
    if (paymentOption !== "PayPal") {
      if (!deviceSupportsPopups(navigator.userAgent)) {
        setIsLoading(false);
        alert("Your device or browser does not support popups. Please use a different device or browser.");
      }
      const left = window.screen.width / 2 - 500 / 2;
      const top = window.screen.height / 2 - 700 / 2;
      windowReference = window.open(
        "about:blank",
        "_blank",
        `toolbar=no, location=no, directories=no, status=1, menubar=no, scrollbars=1, resizable=1, copyhistory=no, width=500, height=700, top=${top}, left=${left}`
      );
      if (windowReference) {
        windowReference.document.write(`
          <html>
            <head>
                <title>Loading...</title>
                <!-- Adding Google Fonts (Roboto) -->
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
                <style>
                    /* Keyframes for spinner animation */
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }

                    /* Page styling */
                    body {
                        font-family: 'Roboto', sans-serif; /* Applying Roboto font */
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        margin: 0;
                        background-color: #fff; /* Light background for better contrast */
                    }

                    /* Revolut logo */
                    .logo {
                        display: block;
                        margin: 0 auto 20px; /* Centered and spaced logo */
                        width: 80px;
                        height: 80px;
                    }

                    /* Loading spinner */
                    .spinner {
                        border: 4px solid #f3f3f3;
                        border-top: 4px solid #000;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        animation: spin 1s linear infinite;
                        margin: 10px auto;
                    }

                    /* Loading text */
                    p {
                        font-size: 20px;
                        text-align: center;
                        color: #333; /* Darker color for better readability */
                    }
                </style>
            </head>
            <body>
                <div>
                    <!-- Revolut logo (ensure the URL is correct and publicly accessible) -->
                    <img src="${revolutLogo}" alt="Revolut Logo" class="logo">

                    <!-- Spinner -->
                    <div class="spinner"></div>
                </div>
            </body>
        </html>
      `);
      }
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((res) => res.json())
      .catch((error) => {
        windowReference?.close();
        setIsLoading(false);
        console.error("Failed to place order.", error);
        toast({
          title: "Failed to place order.",
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      })
      .then((data) => {
        if (data.error) {
          toast({
            title: data.error,
            variant: "left-accent",
            status: "error",
            duration: 2000,
            position: "bottom-right",
          });
          setIsLoading(false);
        } else {
          if (paymentOption === "PayPal") {
            setOrderId(data.order_id);
            onOpen();
          } else {
            handleRevolutPayment(data, windowReference);
          }
        }
      })
      .catch((error) => {
        windowReference?.close();
        setIsLoading(false);
        console.error("Failed to place order.", error);
        toast({
          title: "Failed to place order.",
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
  };

  const handleRevolutPayment = (data, windowReference) => {
    setIsLoading(false);
    if (data?.checkout_url) {
      startPayment(
        data,
        windowReference,
        handlePaymentSuccess,
        handlePaymentError
      );
    }
  };

  const handlePaymentSuccess = (details, msg = null) => {
    toast({
      title: "Payment Successful",
      description: msg
        ? msg
        : `Transaction completed by ${details.payer.name.given_name}`,
      status: "success",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
    setShowThankyou(true);
    setTimeout(() => {
      setShowThankyou(false);
      handleReset();
    }, 1500);
  };

  const handlePaymentError = (error) => {
    console.error("Payment Error:", error);
    toast({
      title: "Payment Failed",
      description: "An error occurred during the payment process.",
      status: "error",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
  };

  return (
    <Container maxW={"7xl"}>
      <Grid
        templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
        gap={10}
        my={6}
      >
        <Box>
          <OrderDetails />
        </Box>

        <Box>
          <BillingDetails onPaymentOptionChange={setPaymentOption} />
        </Box>

        <Box
          gridColumn={{ base: "1", sm: "span 2", md: "auto" }}
          maxW={"md"}
          mx={"auto"}
        >
          <ProductDetails
            setActiveStep={setActiveStep}
            setDiscountedTotal={setTotal}
          />
        </Box>
      </Grid>

      <Box
        display={"flex"}
        alignItems={"center"}
        w={"100%"}
        mb={20}
        flexDirection={"column"}
        gap={4}
      >
        <CustomButton
          text={"Place Order"}
          bgColor={"green.1000"}
          width={"180px"}
          onClick={handlePlaceOrder}
          loading={isLoading}
        />

        <CustomButton
          text={"Back"}
          onClick={handlePreviousStep}
          width={"180px"}
        />
      </Box>

      <Box display={"none"}>
        <PayPalComponent
          orderId={orderId}
          amount={total}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
          isOpen={isOpen}
          onClose={onClose}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Box>

      {showThankyou && <Thankyou />}

      {/* Optional Overlay for Revolut */}
      {(isOverlayVisible || paymentStatus === null) && (
        <RevolutPaymentOverlay
          focusPopup={focusPopup}
          closePayment={closePayment}
          isOverlayVisible={isOverlayVisible}
          paymentStatus={paymentStatus}
        />
      )}
    </Container>
  );
};

export default Checkout;
