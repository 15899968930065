import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  id: Date.now(),
  address: "",
  propertyType: null,
  propertySize: null,
  access: null,
  comments: "",
  // dateTime: [],
  date: [],
  time: [],
  officeName: "",
  officeAddress: "",
  tenantName: "",
  tenantNumber: "",
  porterEmail: "",
  boxCode: "",
  selectedOption: "As soon as possible",
  selectedServices: [],
  selectedServicesTypes: {},
  selectedBundle: [],
  selectedAddOn: [],
  servicePrice: {},
  discountedPrice: 0,
  price: 0,
  productService: [],
  productBundle: [],
  productAddOn: [],
  selectedServicesObj: [],
  selectedBundleObj: [],
  selectedAddOnObj: [],
  externalImages: [],
};

// const isDuplicateObject = (array, obj) => {
//     let isDuplicateFound = false;
//     let DuplicateIndex = -1;
//     for (let index = 0; index < array.length; index++) {
//         isDuplicateFound = Object.entries(array[index]).filter(([key, value]) => obj[key] !== value).length < 1;
//         DuplicateIndex = index;
//         if (isDuplicateFound) {
//             break;
//         }
//     }
//     return DuplicateIndex;
// }

// const isDuplicate = (array, obj) => {
//     return array.findIndex(item => item.id === obj.id);
// }

const useProductStore = create(
  // persist(
  (set) => ({
    ...initialState,
    propertyDetails: initialState,
    properties: [],

    addProperty: (propertyDetails, isContinue = false) => {
      return set((state) => {
        const modifiedState = {
          ...state,
          selectedServices: isContinue ? state.selectedServices : [],
          selectedBundle: isContinue ? state.selectedBundle : [],
          selectedAddOn: isContinue ? state.selectedAddOn : [],
          selectedServicesTypes: isContinue ? state.selectedServicesTypes : [],
          externalImages: isContinue ? state.externalImages : [],
          selectedOption: isContinue
            ? state.selectedOption
            : "As soon as possible",
        };

        const existingIndex = state.properties.findIndex(
          (property) => property.id === propertyDetails.id
        );

        if (existingIndex > -1) {
          // Property already exists, update it
          modifiedState.properties = state.properties.map((property, index) =>
            index === existingIndex
              ? { ...property, ...propertyDetails }
              : property
          );
        } else {
          // Property doesn't exist, add it
          modifiedState.properties = [
            ...state.properties,
            { ...propertyDetails },
          ];
        }
        return modifiedState;
      });
    },

    updateProperty: (index, newDetails) =>
      set((state) => ({
        properties: state.properties.map((property, i) =>
          i === index ? { ...property, ...newDetails } : property
        ),
      })),

    removeProperty: (id) =>
      set((state) => {
        // Log the current state before making changes

        // const deletedProperties = state.properties.filter(property => property.id !== id);
        const propertyIndex = state.properties.indexOf(id);

        const deletedProperties = state.properties.splice(propertyIndex, 1);

        // Log the updated properties after filtering

        const lastProperty =
          state.properties.length > 0
            ? state.properties[state.properties.length - 1]
            : initialState;

        // Log the last property

        return {
          properties: state.properties,
          propertyDetails: lastProperty,
          // set((state) => {
          id: lastProperty.id,
          address: lastProperty.address,
          propertyType: lastProperty.propertyType,
          propertySize: lastProperty.propertySize,
          access: lastProperty.access,
          comments: lastProperty.comments,
          // dateTime: lastProperty.dateTime,
          date: lastProperty.date,
          time: lastProperty.time,
          officeName: lastProperty.officeName,
          officeAddress: lastProperty.officeAddress,
          tenantName: lastProperty.tenantName,
          tenantNumber: lastProperty.tenantNumber,
          porterEmail: lastProperty.porterEmail,
          boxCode: lastProperty.boxCode,
          selectedOption: lastProperty.selectedOption,
          selectedServices: lastProperty.selectedServices,
          selectedServicesTypes: lastProperty.selectedServicesTypes,
          selectedBundle: lastProperty.selectedBundle,
          selectedAddOn: lastProperty.selectedAddOn,
          servicePrice: lastProperty.servicePrice,
          discountedPrice: lastProperty.discountedPrice,
          price: lastProperty.price,
          productService: lastProperty.productService,
          productBundle: lastProperty.productBundle,
          productAddOn: lastProperty.productAddOn,
          externalImages: lastProperty.externalImages,
          // })
        };
      }),

    setPropertyDetails: (newDetails) =>
      set((state) => ({
        propertyDetails: { ...state.propertyDetails, ...newDetails },
      })),

    setEditPropertyDetails: (newDetails) => {
      return set((state) => {
        return {
          ...state,
          id: newDetails.id,
          address: newDetails.address,
          propertyType: newDetails.propertyType,
          propertySize: newDetails.propertySize,
          access: newDetails.access,
          comments: newDetails.comments,
          // dateTime: newDetails.dateTime,
          date: newDetails.date,
          time: newDetails.time,
          officeName: newDetails.officeName,
          officeAddress: newDetails.officeAddress,
          tenantName: newDetails.tenantName,
          tenantNumber: newDetails.tenantNumber,
          porterEmail: newDetails.porterEmail,
          boxCode: newDetails.boxCode,
          selectedOption: newDetails.selectedOption,
          selectedServices: newDetails.selectedServices,
          selectedServicesTypes: newDetails.selectedServicesTypes,
          selectedBundle: newDetails.selectedBundle,
          selectedAddOn: newDetails.selectedAddOn,
          servicePrice: newDetails.servicePrice,
          discountedPrice: newDetails.discountedPrice,
          price: newDetails.price,
          productService: newDetails.productService,
          productBundle: newDetails.productBundle,
          productAddOn: newDetails.productAddOn,
          externalImages: newDetails.externalImages,
        };
      });
    },

    setSelectedServices: (update) =>
      set((state) => ({
        selectedServices:
          typeof update === "function"
            ? update(state.selectedServices)
            : update,
      })),

    setSelectedServicesTypes: (update) =>
      set((state) => ({
        selectedServicesTypes:
          typeof update === "function"
            ? update(state.selectedServicesTypes)
            : update,
      })),

    setSelectedBundle: (update) =>
      set((state) => ({
        selectedBundle:
          typeof update === "function" ? update(state.selectedBundle) : update,
      })),

    setSelectedAddOn: (update) =>
      set((state) => ({
        selectedAddOn:
          typeof update === "function" ? update(state.selectedAddOn) : update,
      })),

    setServicePrice: (update) =>
      set((state) => ({
        servicePrice:
          typeof update === "function" ? update(state.servicePrice) : update,
      })),

    setExternalImages: (update) =>
      set((state) => ({
        externalImages:
          typeof update === "function" ? update(state.externalImages) : update,
      })),

    setDiscountedPrice: (discountedPrice) => set({ discountedPrice }),
    setPrice: (price) => set({ price }),
    setProductService: (productService) => set({ productService }),
    setProductBundle: (productBundle) => set({ productBundle }),
    setProductAddOn: (productAddOn) => set({ productAddOn }),
    resetProductDetails: () => {
      try {
        set({ ...initialState, propertyDetails: initialState, properties: [] });
      } catch (error) {}
    },
  })
  // {
  //     name: 'property-storage',
  //     storage: createJSONStorage(() => sessionStorage),
  // },)
);

export default useProductStore;
