import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import paypal from "../../assets/images/Paypal.png";
import applePay from "../../assets/images/ApplePay.png";
import googlePay from "../../assets/images/gpay.png";
// import revolut from "../../assets/images/revolut.svg";
// import visa from "../../assets/images/Visa.png";
import useOrderStore from "../../zustand/useOrderStore";

const BillingDetails = ({ onPaymentOptionChange }) => {
  const [paymentOption, setPaymentOption] = useState("Apple Pay");

  const { orderDetails, setOrderDetails } = useOrderStore();

  const handleCheckboxChange = (field, value) => {
    setOrderDetails({ ...orderDetails, [field]: value });
  };

  const handlePaymentOptionChange = (newPaymentOption) => {
    setPaymentOption(newPaymentOption);
    onPaymentOptionChange(newPaymentOption);
  };

  return (
    <Flex flexDirection={"column"} gap={4}>
      <Heading textAlign={"center"} as="h3" variant={"h3"}>
        Billing Details
      </Heading>

      <Box bg={"gray.100"} rounded={"8px"}>
        <RadioGroup
          onChange={handlePaymentOptionChange}
          value={paymentOption}
          p={6}
          pb={4}
        >
          <Stack direction="column">
            {/* <Radio value="Debit & Credit Cards">
              <Box
                display={"flex"}
                gap={4}
                alignItems={"center"}
                fontWeight={600}
              >
                Debit & Credit Cards
                <Image src={visa} />
              </Box>
            </Radio> */}

            <Radio value="PayPal">
              <Image src={paypal} />
            </Radio>
            <Radio value="Apple Pay">
              <Box display={"flex"} width={"full"}>
                <Image height={16} src={applePay}  />
                <Image height={16} src={googlePay} />
              </Box>
            </Radio>
            <Radio value="Revolut">
              <Box fontWeight={600}>Pay with Card or by Bank</Box>
              <Box id="payment-request" />
            </Radio>
          </Stack>
        </RadioGroup>

        <FormControl
          isRequired
          borderTopWidth={1}
          px={6}
          py={3}
          borderColor={"#BDBDBD"}
        >
          <Checkbox
            fontWeight={600}
            value={orderDetails.acceptTC}
            onChange={(e) => handleCheckboxChange("acceptTC", e.target.checked)}
          >
            <Box fontSize={"14px"}>
              I accept{" "}
              {orderDetails.acceptTC === true ? (
                <a
                  href="https://www.spacephoto.co.uk/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
              ) : (
                "terms and conditions"
              )}{" "}
              *
            </Box>
          </Checkbox>
        </FormControl>
      </Box>
    </Flex>
  );
};

export default BillingDetails;
