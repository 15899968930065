import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  Box,
} from "@chakra-ui/react";

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "GBP",
  intent: "capture",
};

const PayPalComponent = ({
  orderId,
  amount,
  onPaymentSuccess,
  onPaymentError,
  isOpen,
  onClose,
  setIsLoading,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Box display={isLoading ? "none" : "block"}>
        <ModalOverlay />
        <ModalContent bg={"white"}>
          <ModalCloseButton />
          <ModalBody mt={8}>
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: amount,
                        },
                        custom_id: orderId,
                        // shipping: {
                        //   name: {
                        //     full_name: "John Doe",
                        //   },
                        //   address: {
                        //     address_line_1: "123 Townsend St",
                        //     admin_area_2: "San Francisco",
                        //     admin_area_1: "CA",
                        //     postal_code: "94107",
                        //     country_code: "US",
                        //   },
                        // },
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING",
                      user_action: "PAY_NOW",
                      landing_page: "BILLING",
                      payee_preferred: "NO_BILLING",
                    },
                  });
                }}
                onApprove={async (data, actions) => {
                  return actions.order.capture().then((details) => {
                    fetch(
                      `${process.env.REACT_APP_BACKEND_URL}/api/orders/complete`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          order_id: orderId,
                          status: "completed",
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        if (data.error) {
                          onPaymentError(data.error);
                        } else {
                          onPaymentSuccess(details);
                        }
                      });
                  });
                }}
                onError={onPaymentError}
                onInit={() => setIsLoading(false)}
              />
            </PayPalScriptProvider>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default PayPalComponent;
